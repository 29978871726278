var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-full w-full my-3 flex flex-col gap-3"},[_c('c-card',{staticClass:"flex justify-between items-center p-4"},[_c('div',{staticClass:"flex justify-start items-center gap-2 divide-x divide-romanSilver"},[_c('p',{staticClass:"font-bold text-darkPurple text-lg"},[_vm._v(" "+_vm._s(_vm.$DATEFORMAT(new Date(_vm.selectedDate), "MMMM yyyy"))+" ")]),_c('div',{staticClass:"cursor-pointer",on:{"click":_vm.checkPrivilege}},[_c('p',{staticClass:"text-sm font-normal text-desire pl-2 hover:underline"},[_vm._v(" "+_vm._s(_vm.conflictedLeave.length)+" Conflicts ")])])]),_c('div',{staticClass:"flex justify-start items-center gap-6"},[_c('span',{staticClass:"cursor-pointer hover:bg-cultured p-2 rounded",on:{"click":function($event){return _vm.$refs.calendar.previous()}}},[_c('c-icon',{attrs:{"icon-name":"icon-arrow-left","size":"xs"}})],1),_c('span',{staticClass:"cursor-pointer hover:bg-cultured p-2 rounded",on:{"click":function($event){return _vm.$refs.calendar.next()}}},[_c('c-icon',{attrs:{"icon-name":"icon-arrow-right","size":"xs"}})],1)])]),_c('calendar',{ref:"calendar",staticClass:"calendar-class",attrs:{"time":false,"hide-title-bar":"","hide-view-selector":"","events":_vm.conflictedLeave,"selected-date":_vm.selectedDate,"events-on-month-view":"","disable-views":['years', 'year', 'week', 'day'],"cell-contextmenu":true},on:{"view-change":function($event){return _vm.onChangeView($event)}},scopedSlots:_vm._u([{key:"event",fn:function({ event }){return [(
          _vm.isEqual(
            new Date(new Date(event.date).getMonth()),
            new Date(new Date(_vm.selectedDate).getMonth())
          )
        )?[_c('div',{staticClass:"flex justify-center items-center gap-2 cursor-pointer",on:{"click":function($event){(_vm.isOpen = true), (_vm.activeConflict = event)}}},[_c('p',{staticClass:"text-desire text-xs bg-red-100 border border-desire border-solid rounded-md p-2 w-full"},[_vm._v(" "+_vm._s(event.conflict.length)+" Employee "),_c('span',{staticClass:"underline ml-1"},[_vm._v("View")])])])]:_vm._e()]}}])}),(_vm.isOpen)?_c('RightSideBar',{attrs:{"close-button":true,"width":"width:520px"},on:{"close":function($event){_vm.isOpen = false}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex flex-col justify-start items-start gap-2"},[_c('div',{staticClass:"flex justify-start items-center gap-0"},[_c('back-button',{staticStyle:{"padding":"0"},attrs:{"variant":"secondary"},on:{"click":function($event){_vm.isOpen = false}}}),_c('span',{staticClass:"font-bold text-lg text-darkPurple"},[_vm._v("Conflicts")])],1),_c('span',{staticClass:"font-normal text-sm text-romanSilver"},[_vm._v(" The system only shows conflicts for the same job role and function. ")])])]},proxy:true}],null,false,2737123608)},[(_vm.conflictedLeave.length)?_c('div',{staticClass:"flex flex-col justify-start gap-12"},_vm._l((_vm.conflictedLeave),function(item,i){return _c('div',{key:JSON.stringify(item) + i,staticClass:"w-full flex flex-col justify-start items-start gap-4"},[_c('div',{staticClass:"w-full flex justify-between items-center",on:{"click":function($event){!item.active
              ? _vm.$set(item, 'active', true)
              : _vm.$delete(item, 'active')}}},[_c('span',{staticClass:"font-semibold text-sm text-romanSilver"},[_vm._v(_vm._s(_vm.$DATEFORMAT(new Date(item.date), "d MMM, yyyy")))]),_c('div',{staticClass:"flex justify-start items-center gap-1 cursor-pointer"},[_c('span',{staticClass:"font-semibold text-sm text-jet"},[_vm._v(_vm._s(item.conflict.length)+" Employees")]),_c('c-icon',{attrs:{"icon-name":item.active ? 'chevronUp' : 'chevronDown',"size":"xs"}})],1)]),(item.active)?_c('div',{staticClass:"w-full grid grid-cols-2 gap-4"},_vm._l((item.conflict),function(employee,index){return _c('div',{key:JSON.stringify(employee) + index},[_c('div',{staticClass:"flex justify-start items-start gap-2",class:{ 'justify-end': index % 2 }},[_c('div',[(employee.photo)?_c('span',{staticClass:"flex justify-center items-center w-9 h-9"},[_c('img',{staticClass:"w-9 h-9 rounded",attrs:{"src":employee.photo,"alt":"profile photo"}})]):_c('div',{staticClass:"flex justify-center items-center w-9 h-9 rounded border"},[_c('span',{staticClass:"text-blueCrayola text-center font-semibold text-lg p-2"},[_vm._v(" "+_vm._s(_vm.$getInitials(`${employee.fname} ${employee.lname}`))+" ")])])]),_c('div',{staticClass:"flex flex-col justify-start gap-1"},[_c('span',{staticClass:"font-semibold text-darkPurple text-base"},[_vm._v(" "+_vm._s(employee.fname)+" "+_vm._s(employee.lname)+" ")]),_c('span',{staticClass:"font-normal text-romanSilver text-xs uppercase"},[_vm._v(" "+_vm._s(employee.userDesignation && employee.userDesignation.name)+" ")])])])])}),0):_vm._e()])}),0):_c('div',{staticClass:"flex flex-col justify-center items-center gap-0"},[_c('c-icon',{staticClass:"w-80",attrs:{"icon-name":"empty-conflicts","size":""}}),_c('p',{staticClass:"text-base text-jet -mt-10"},[_vm._v(" There is no leave conflict for this month. ")])],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }