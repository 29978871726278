<template>
  <div class="h-full w-full my-3 flex flex-col gap-3">
    <c-card class="flex justify-between items-center p-4">
      <div
        class="flex justify-start items-center gap-2 divide-x divide-romanSilver"
      >
        <p class="font-bold text-darkPurple text-lg">
          {{ $DATEFORMAT(new Date(selectedDate), "MMMM yyyy") }}
        </p>
        <div class="cursor-pointer" @click="checkPrivilege">
          <p class="text-sm font-normal text-desire pl-2 hover:underline">
            {{ conflictedLeave.length }} Conflicts
          </p>
        </div>
      </div>
      <div class="flex justify-start items-center gap-6">
        <span
          class="cursor-pointer hover:bg-cultured p-2 rounded"
          @click="$refs.calendar.previous()"
        >
          <c-icon icon-name="icon-arrow-left" size="xs" />
        </span>
        <span
          class="cursor-pointer hover:bg-cultured p-2 rounded"
          @click="$refs.calendar.next()"
        >
          <c-icon icon-name="icon-arrow-right" size="xs" />
        </span>
      </div>
    </c-card>
    <calendar
      ref="calendar"
      :time="false"
      hide-title-bar
      hide-view-selector
      :events="conflictedLeave"
      :selected-date="selectedDate"
      events-on-month-view
      :disable-views="['years', 'year', 'week', 'day']"
      :cell-contextmenu="true"
      @view-change="onChangeView($event)"
      class="calendar-class"
    >
      <template #event="{ event }">
        <template
          v-if="
            isEqual(
              new Date(new Date(event.date).getMonth()),
              new Date(new Date(selectedDate).getMonth())
            )
          "
        >
          <div
            @click="(isOpen = true), (activeConflict = event)"
            class="flex justify-center items-center gap-2 cursor-pointer"
          >
            <p
              class="text-desire text-xs bg-red-100 border border-desire border-solid rounded-md p-2 w-full"
            >
              {{ event.conflict.length }} Employee
              <span class="underline ml-1">View</span>
            </p>
          </div>
        </template>
      </template>
    </calendar>

    <RightSideBar
      v-if="isOpen"
      @close="isOpen = false"
      :close-button="true"
      width="width:520px"
    >
      <template v-slot:title>
        <div class="flex flex-col justify-start items-start gap-2">
          <div class="flex justify-start items-center gap-0">
            <back-button
              variant="secondary"
              style="padding: 0"
              @click="isOpen = false"
            />
            <span class="font-bold text-lg text-darkPurple">Conflicts</span>
          </div>
          <span class="font-normal text-sm text-romanSilver">
            The system only shows conflicts for the same job role and function.
          </span>
        </div>
      </template>

      <div
        v-if="conflictedLeave.length"
        class="flex flex-col justify-start gap-12"
      >
        <div
          v-for="(item, i) in conflictedLeave"
          :key="JSON.stringify(item) + i"
          class="w-full flex flex-col justify-start items-start gap-4"
        >
          <div
            class="w-full flex justify-between items-center"
            @click="
              !item.active
                ? $set(item, 'active', true)
                : $delete(item, 'active')
            "
          >
            <span class="font-semibold text-sm text-romanSilver">{{
              $DATEFORMAT(new Date(item.date), "d MMM, yyyy")
            }}</span>
            <div class="flex justify-start items-center gap-1 cursor-pointer">
              <span class="font-semibold text-sm text-jet"
                >{{ item.conflict.length }} Employees</span
              >
              <c-icon
                :icon-name="item.active ? 'chevronUp' : 'chevronDown'"
                size="xs"
              />
            </div>
          </div>
          <div v-if="item.active" class="w-full grid grid-cols-2 gap-4">
            <div
              v-for="(employee, index) in item.conflict"
              :key="JSON.stringify(employee) + index"
            >
              <div
                class="flex justify-start items-start gap-2"
                :class="{ 'justify-end': index % 2 }"
              >
                <div>
                  <span
                    v-if="employee.photo"
                    class="flex justify-center items-center w-9 h-9"
                  >
                    <img
                      :src="employee.photo"
                      class="w-9 h-9 rounded"
                      alt="profile photo"
                    />
                  </span>
                  <div
                    v-else
                    class="flex justify-center items-center w-9 h-9 rounded border"
                  >
                    <span
                      class="text-blueCrayola text-center font-semibold text-lg p-2"
                    >
                      {{ $getInitials(`${employee.fname} ${employee.lname}`) }}
                    </span>
                  </div>
                </div>
                <div class="flex flex-col justify-start gap-1">
                  <span class="font-semibold text-darkPurple text-base">
                    {{ employee.fname }} {{ employee.lname }}
                  </span>
                  <span class="font-normal text-romanSilver text-xs uppercase">
                    {{
                      employee.userDesignation && employee.userDesignation.name
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="flex flex-col justify-center items-center gap-0">
        <c-icon icon-name="empty-conflicts" size="" class="w-80" />
        <p class="text-base text-jet -mt-10">
          There is no leave conflict for this month.
        </p>
      </div>
    </RightSideBar>
  </div>
</template>

<script>
import { isEqual } from "date-fns";
import Calendar from "vue-cal";
import "vue-cal/dist/vuecal.css";
import CCard from "@scelloo/cloudenly-ui/src/components/card";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import RightSideBar from "@/components/RightSideBar";
import CIcon from "@/components/Icon";

export default {
  components: {
    Calendar,
    BackButton,
    CCard,
    CIcon,
    RightSideBar,
  },
  watch: {
    activeConflict(value) {
      this.conflictedLeave.forEach((item) => this.$delete(item, "active"));
      const findIndex = this.conflictedLeave.findIndex(
        (item) => item.date === value.date
      );
      if (findIndex > -1)
        this.$set(this.conflictedLeave[findIndex], "active", true);
    },
  },
  data() {
    return {
      isEqual,
      isOpen: false,
      selectedDate: new Date(),
      activeConflict: {},
      calendarData: [],
      conflictedLeave: [],
    };
  },
  methods: {
    async checkPrivilege() {
      try {
        await this.$handlePrivilege("leavePlan", "viewConflict");
        this.isOpen = true;
        this.activeConflict = {};
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    onChangeView($event) {
      this.selectedDate = $event.startDate;
      if (this.$route.query.slug === "admin") {
        this.getAdminLeaveCalendar($event.startDate);
      } else {
        this.getManagerLeaveCalendar($event.startDate);
      }
    },
    async getAdminLeaveCalendar(selectedDate) {
      const payload = {
        leaveMonth: selectedDate.getMonth() + 1,
        leaveYear: selectedDate.getFullYear(),
      };

      await this.$_getAdminLeaveCalendar(payload).then(async ({ data }) => {
        this.calendarData = data.calendarData;
        this.conflictedLeave = await data.calendarData
          .map((item) => ({
            ...item,
            start: item.date,
            end: item.date,
          }))
          ?.filter((v) => v.conflict.length);
      });
    },

    async getManagerLeaveCalendar(selectedDate) {
      const payload = {
        leaveMonth: selectedDate.getMonth() + 1,
        leaveYear: selectedDate.getFullYear(),
      };

      await this.$_getManagerLeaveCalendar(payload).then(async ({ data }) => {
        this.calendarData = data.calendarData;
        this.conflictedLeave = await data.calendarData
          .map((item) => ({
            ...item,
            start: item.date,
            end: item.date,
          }))
          ?.filter((v) => v.conflict.length);
      });
    },
  },
  created() {
    if (this.$route.query.slug === "admin") {
      this.getAdminLeaveCalendar(new Date());
    } else {
      this.getManagerLeaveCalendar(new Date());
    }
  },
};
</script>

<style scoped>
.calendar-class {
  background: #eeeeee52;
  border-radius: 8px;
  padding: 24px;
  padding-top: 8px;
  border: 0 !important;
}
::v-deep .vuecal {
  border: 0 !important;
}
::v-deep .vuecal .vuecal__header {
  border: 0 !important;
}
::v-deep .vuecal .weekday-label {
  display: flex;
  justify-content: flex-start;
}
::v-deep .vuecal .weekday-label span {
  font-family: "Nunito Sans";
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #878e99;
}
::v-deep .vuecal .vuecal__cell {
  height: 126px !important;
  border-color: #878e99 !important;
  border-style: solid !important;
  border-width: 0.5px !important;
}
::v-deep .vuecal .vuecal__cell-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px;
}
</style>
